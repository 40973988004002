:root {
  --page-padding: 30px;
  --page-background: white;
  --z-lowest: 0;
  --z-middle: 5;
  --z-highest: 10;
  --f-s: .75rem;
  --f-m: 1rem;
  --f-l: 1.333rem;
  --f-xl: 1.777rem;
  --f-xxl: 2.369rem;
  --f-xxxl: 3.157rem;
  --f-xxxxl: 4.209rem;
  --s-m: 24px;
  --s-l: 48px;
  --s-xl: 72px;
  --s-xxl: 144px;
  --s-xxxl: 216px;
  --c-text: #0f0f0f;
  --c-text-secondary: #919191;
  --c-primary: #c07083;
  --c-secondary: #478778;
  --footer-color: #fff;
}

@media (width >= 1441px) {
  :root {
    --f-s: .75vw;
    --f-m: 1vw;
    --f-l: 1.333vw;
    --f-xl: 1.777vw;
    --f-xxl: 2.369vw;
    --f-xxxl: 3.157vw;
    --f-xxxxl: 4.209vw;
    --s-m: 2.4vw;
    --s-l: 4.8vw;
    --s-xl: 7.2vw;
    --s-xxl: 14.4vw;
    --s-xxxl: 21.6vw;
  }
}

* {
  transition: all .3s cubic-bezier(.6, -.28, .735, .045);
}

::selection {
  background: #00945940;
}

html {
  font-size: 16px;
}

@media (width >= 600px) {
  html {
    font-size: 17px;
  }
}

@media (width >= 960px) {
  html {
    font-size: 18px;
  }
}

body {
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  color: var(--c-text);
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
  font-family: -apple-system, sans-serif;
  line-height: 1.5111;
  display: flex;
  overflow-x: hidden;
}

main {
  flex: 1;
}

a {
  color: inherit;
  text-decoration: none;
  transition: none;
}

p {
  letter-spacing: -.003em;
  word-break: break-word;
  color: var(--c-text);
}

ul {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

ol {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 26px;
}

@media (width >= 600px) {
  ol {
    padding-left: 40px;
  }
}

img {
  filter: grayscale(.66);
  max-width: 100%;
  height: auto;
}

img:hover {
  filter: grayscale(0);
}

h1, h2, p {
  margin-top: 0;
  margin-bottom: 0;
}

li {
  list-style-type: none;
}

address {
  font-style: normal;
}

.u-overflow-y-hidden {
  position: fixed;
  overflow-y: hidden;
}

.grid1 {
  grid-gap: 20px;
  margin-top: var(--s-l);
  display: grid;
}

@media (width >= 600px) {
  .grid1 {
    grid-gap: 40px;
    grid-template-columns: 2.555fr 1fr;
  }

  .grid1 > img {
    display: block;
  }
}

.loading footer {
  display: none;
}

:hover, :before:hover, :after:hover {
  transform: rotate(200deg);
}

@keyframes float {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-4px);
  }

  100% {
    transform: translateY(0);
  }
}

.float {
  animation: 5s ease-in infinite float;
}
/*# sourceMappingURL=index.26a8c4c1.css.map */
