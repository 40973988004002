:root {
    --page-padding: 30px;
    --page-background: white;

    --z-lowest: 0;
    --z-middle: 5;
    --z-highest: 10;

    /* 1.414 augmented fourth scale */
    --f-s: 0.75rem;
    --f-m: 1rem;
    --f-l: 1.333rem;
    --f-xl: 1.777rem;
    --f-xxl: 2.369rem;
    --f-xxxl: 3.157rem;
    --f-xxxxl: 4.209rem;

    --s-m: 24px;
    --s-l: 48px;
    --s-xl: 72px;
    --s-xxl: 144px;
    --s-xxxl: 216px;

    --c-text: #0f0f0f;
    --c-text-secondary: #919191;

    /* this color will also be used within the mycel */
    --c-primary: #c07083;
    --c-secondary: #478778;


    --footer-color: #fff;
}

@media (min-width: 1441px) {
    :root {
        --f-s: 0.75vw;
        --f-m: 1vw;
        --f-l: 1.333vw;
        --f-xl: 1.777vw;
        --f-xxl: 2.369vw;
        --f-xxxl: 3.157vw;
        --f-xxxxl: 4.209vw;

        --s-m: 2.4vw;
        --s-l: 4.8vw;
        --s-xl: 7.2vw;
        --s-xxl: 14.4vw;
        --s-xxxl: 21.6vw;
    }
}

* {
    transition: all cubic-bezier(0.6, -0.28, 0.735, 0.045) 300ms;
}

::selection {
    background: #00945940;
}

html {
    font-size: 16px;
}

@media (min-width: 600px) {
    html {
        font-size: 17px;
    }
}

@media (min-width: 960px) {
    html {
        font-size: 18px;
    }
}

body {
    margin: 0;
    font-family: -apple-system, sans-serif;
    display: flex;
    min-height: 100vh;
    line-height: 1.5111;
    flex-direction: column;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    color: var(--c-text);

    /* improve later*/

    overflow-x: hidden;
}

main {
    flex: 1;
}

a {
    color: inherit;
    text-decoration: none;
    /* may add something but faster? */
    transition: none;
}

p {
    letter-spacing: -0.003em;
    word-break: break-word;
    color: var(--c-text);
}

ul {
    list-style: none;
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
}

ol {
    padding-left: 26px;
    margin-top: 0;
    margin-bottom: 0;
}

@media (min-width: 600px) {
    ol {
        padding-left: 40px;
    }
}

img {
    max-width: 100%;
    height: auto;
    filter: grayscale(0.66);
}

img:hover {
    filter: grayscale(0);
}

h1,
h2,
p {
    margin-top: 0;
    margin-bottom: 0;
}

li {
    list-style-type: none;
}

address {
    font-style: normal;
}

.u-overflow-y-hidden {
    overflow-y: hidden;
    position: fixed;
}

/* on thoughts page */
.grid1 {
    display: grid;
    grid-gap: 20px;
    margin-top: var(--s-l);
}

/* TODO: create nice img galerie :) with mask pictures ad wood? */
@media (min-width: 600px) {
    .grid1 {
        grid-template-columns: 2.555fr 1fr;
        grid-gap: 40px;
    }

    .grid1>img {
        display: block;
    }
}

.loading footer {
    display: none;
}

/* You observing the screen makes it fall back into order. But Chaos had to be there first. */
/* 
1. trigger a hover on a element in the middle of page, maybe invisible fix element? yes
2. have no animation first, just let the element go to the final state
3. then re-add animation and let the mouse hover disapear as well as unmount the element that triggered the chaos
/* first have chaos, then let it come back to normal */

*:hover,
::before:hover,
::after:hover {
    transform: rotate(200deg);
}

@keyframes float {
    0% {
        transform: translatey(0px);
    }

    50% {
        transform: translatey(-4px);
    }

    100% {
        transform: translatey(0px);
    }
}

.float {
    animation: 5s ease-in infinite float;
}